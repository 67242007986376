class Gallery {
  constructor() {
    $('.js-gallery').lightGallery({
      selector: '.js-gallery-selector',
      zoom: true,
      share: false,
      download: false,
      thumbnail: false
    });
  }
}

export default Gallery;
