class Menu {
  constructor() {
    const navbarToggle = $('.js-toggle-menu');
    const menu = $('.menu');

    this.openMenu(navbarToggle, menu);
    this.closeOutside(navbarToggle, menu);
  }

  openMenu(navbarToggle, menu) {
    const that = this;

    navbarToggle.on('click', function(e) {
      e.defaultPrevented;

      // Toggle overlay
      if (!navbarToggle.hasClass('-active')) {
        that.toggleOverlay(false);
      } else {
        that.toggleOverlay(true);
      }

      // Toggle menu
      if (!navbarToggle.hasClass('-active')) {
        that.toggleMenu(navbarToggle, menu, false);
      } else {
        that.toggleMenu(navbarToggle, menu, true);
      }
    });
  }

  closeMenu(navbarToggle, menu) {
    const that = this;

    // Toggle overlay
    that.toggleOverlay(true);

    // Toggle menu
    that.toggleMenu(navbarToggle, menu, true);
  }

  toggleOverlay(isVisible) {
    let body = $('body');

    if (isVisible) {
      body.removeClass('-overlay');
    } else {
      body.addClass('-overlay');
    }
  }

  toggleMenu(navbarToggle, menu, isVisible) {
    if (isVisible) {
      navbarToggle.removeClass('-active');
      menu.removeClass('-show');
    } else {
      navbarToggle.addClass('-active');
      menu.addClass('-show');
    }
  }

  closeOutside(navbarToggle, menu) {
    const that = this;

    $(document).on('keydown', function(e) {
      if (e.keyCode === 27)
        that.closeMenu(navbarToggle, menu);
    });

    window.addEventListener('click', function(e){
      let container = $('.-overlay');
      if (container.is(e.target)) {
        that.closeMenu(navbarToggle, menu);
      }
    });
  }
}

export default Menu;
