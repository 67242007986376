import flatpickr from '../../../node_modules/flatpickr';
import { Portuguese } from '../../../node_modules/flatpickr/dist/l10n/pt.js';

class Form {
  constructor() {
    this.getCustomFileInput();
    this.getDatePicker();
    this.getDateTimePicker();
    this.getTimePicker();
    this.getRedactor();
    this.getColorPicker();
    this.getInputReplace();
    this.getListbox();
    this.getDuplicateGroup();
  }

  getDuplicateGroup() {
    document.querySelectorAll('.js-group-clone').forEach((group, index) => {
      let addButton  = group.querySelector('.js-add-group'),
          listGroup  = group.querySelector('.js-group-list'),
          item       = group.querySelector('.js-group-item'),
          i          = 0;

      group.querySelectorAll('.js-del-group').forEach((button) => {
        let parent = button.parentNode;
        parent.id = `group-${index}-${++i}`;

        button.addEventListener('click', (event) => {
          this.deleteGroup(group, parent);
        });
      });

      addButton.addEventListener('click', (event) => {
        let clone = item.cloneNode(true);
            clone.querySelector('input').value = '';
            clone.id = `group-${index}-${++i}`;

        if (clone.querySelector('.help-block')) { clone.removeChild(clone.querySelector('.help-block')) }

        clone.querySelector('.js-del-group').addEventListener('click', (event) => {
          this.deleteGroup(group, event.target.parentNode);
        });

        listGroup.appendChild(clone);
      });
    })
  }

  deleteGroup(list, group) {
    if (list.querySelectorAll('.js-group-item').length > 1) {
      group.remove();
    }

    return false;
  }

  getCustomFileInput() {
    $('.js-file-input').filestyle({
      text: 'Selecionar'
    });
  }

  getDatePicker() {
    flatpickr('.js-datepicker', {
      dateFormat: 'd/m/Y',
      locale: Portuguese
    });
  }

  getDateTimePicker() {
    let d = new Date();

    flatpickr('.js-datetimepicker', {
      enableTime: true,
      time_24hr: true,
      dateFormat: 'd/m/Y H:i',
      locale: Portuguese,
      defaultHour: d.getHours(),
      defaultMinute: d.getMinutes()
    });
  }

  getTimePicker() {
    let d = new Date();

    flatpickr('.js-timepicker', {
      enableTime: true,
      time_24hr: true,
      noCalendar: true,
      dateFormat: 'H:i',
      locale: Portuguese,
      defaultHour: d.getHours(),
      defaultMinute: d.getMinutes()
    });
  }

  getRedactor() {
    $R('.js-redactor', {
      lang: 'pt_br',
      styles: true,
      focus: true,
      plugins: [
        'alignment',
        'counter',
        'fontcolor',
        'fontsize',
        'imagemanager',
        'properties',
        'table',
        'video'
      ],
      imageManagerJson: `/admin/api/media/images`,
      imageUpload: `/admin/api/media/images/adicionar`,
      imageResizable: true,
      imagePosition: true,
      callbacks: {
        upload: {
          beforeSend: function(xhr)
          {
            xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
          }
        },
        image: {
          uploadError: function(resp)
          {
            swal({
              title: 'Oops!',
              text: resp.message,
              icon: 'error',
              timer: 2000
            });
          }
        }
      }
    });
  }

  getColorPicker() {
    let colorpicker = $('.js-colorpicker');

    colorpicker.each(function (i) {
      let input = $(this);

      let colorPickerSettings = {
        palette: input.data('palette') || null,
        preferredFormat: 'hex',
        showInput: true
      };

      if(colorPickerSettings.palette !== null) {
        colorPickerSettings.showPalette = true;
        colorPickerSettings.palette = colorPickerSettings.palette.split(',');
      } else {
        colorPickerSettings.showPalette = false;
        colorPickerSettings.palette = [];
      }

      input.spectrum(colorPickerSettings);
    });
  }

  getInputReplace() {
    $('.js-input-replace').keyup(function() {
      let input = $(this),
          character = input.data('character') || '-',
          val = input.val(),
          result = val.replace(' ', character);

      input.val(result);
    });
  }

  getListbox() {
    $('.js-listbox').bootstrapDualListbox({
      filterPlaceHolder: 'Filtrar',
      infoText: false
    });
  }
}

export default Form;
