import Mask from './mask';
import Form from './form';
import Confirm from './confirm';
import InputTag from '../../../node_modules/vue-input-tag';
import VeLine from '../../../node_modules/v-charts/lib/line';
import VeBar from '../../../node_modules/v-charts/lib/bar';
import VePie from '../../../node_modules/v-charts/lib/pie';

class Common {
  constructor() {
    // Vue globally settings
    Vue.options.delimiters = ['<%', '%>'];

    // Component registry
    Vue.component('input-tag', InputTag);
    Vue.component(VeLine.name, VeLine);
    Vue.component(VeBar.name, VeBar);
    Vue.component(VePie.name, VePie);

    new Vue({
      el: '#app'
    });

    // Instance imports
    new Mask();
    new Form();
    new Confirm();

    // Call methods
    this.fixedI10();
    this.disableZoomGesture();
    this.stickyHeading();
    this.initTooltip();
    this.triggerAlert();
  }

  fixedI10() {
    if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
      const msViewportStyle = document.createElement('style');
      msViewportStyle.appendChild(document.createTextNode('@-ms-viewport{width:auto!important}'));
      document.querySelector('head').appendChild(msViewportStyle);
    }
  }

  disableZoomGesture() {
    document.addEventListener('gesturestart', (e) => {
      e.preventDefault();
    });
  }

  stickyHeading() {
    $('.module-heading').stickySidebar({
      topSpacing: 60,
      innerWrapperSelector: '.module-heading-inner'
    });
  }

  initTooltip() {
    $('.js-tooltip').tooltip();
  }

  triggerAlert() {
    $('.alert').not('.alert-dismissible').delay(3000).fadeOut(350);
  }
}

export default Common;
