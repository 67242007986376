class Confirm {
  constructor() {
    const that = this;

    $('.js-confirm').on('click', (e) => {
      e.preventDefault();

      let $this = $(e.currentTarget);
      let settings = {
        button:   $this,
        type:     $this.data('type')    || 'confirm',
        target:   $this.data('target')  || undefined,
        refer:    $this.attr('href')    || undefined,
        title:    $this.data('title')   || 'Atenção',
        message:  $this.data('message') || undefined,
        confirm:  $this.data('confirm') || undefined,
      };

      switch (settings.type) {
        case 'confirm':
          that.proceedRedirect(settings);
          break;
        case 'delete':
          that.deleteData(settings);
          break;
        case 'deleteAll':
          that.deleteAllData(settings);
          break;
        default:
          that.proceedRedirect(settings);
      }
    });
  }

  proceedRedirect(settings) {
    swal({
      icon: 'warning',
      title: settings.title,
      text: settings.message,
      buttons: {
        cancel: {
          text: 'Cancelar',
          visible: true
        },
        confirm: {
          text: settings.confirm,
          closeModal: true,
        }
      },
      dangerMode: true
    })
    .then(isConfirm => {
      if (isConfirm) {
        window.location.href = settings.refer;
      }
    });
  }

  deleteData(settings) {
    swal({
      icon: 'warning',
      title: settings.title,
      text: settings.message,
      buttons: {
        cancel: {
          text: 'Cancelar',
          visible: true
        },
        confirm: {
          text: settings.confirm,
          closeModal: true,
        }
      },
      dangerMode: true
    })
    .then(isConfirm => {
      if (isConfirm) {
        $.ajax({
          'url': settings.refer,
          'type': 'DELETE',
          'dataType': 'json',
          'headers': {
            'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr('content')
          },
          'success'(resp){
            swal({
              title: 'Sucesso!',
              text: resp.message,
              icon: 'success',
              timer: 2000
            });

            let total = $('.js-counter .value');
            if(total.length > 0) {
              let value = total.text();
                  total.text(parseInt(value - 1));
            }

            if(typeof settings.target !== 'undefined') {
              $(settings.target).fadeOut(1500, function(){
                $(this).remove();
              });
            }

            if(resp.redirect) {
              window.location.href = resp.redirect;
            }
          },
          'error'(resp){
            swal({
              title: 'Oops!',
              text: resp.responseJSON.message,
              icon: 'error',
              timer: 2000
            });
          }
        });
      }
    })
    .catch(err => {});
  }

  deleteAllData(settings) {
    swal({
      icon: 'warning',
      title: settings.title,
      text: settings.message,
      buttons: {
        cancel: {
          text: 'Cancelar',
          visible: true
        },
        confirm: {
          text: settings.confirm,
          closeModal: true,
        }
      },
      dangerMode: true
    })
    .then(isConfirm => {
      if (isConfirm) {
        let form = settings.button.closest('.form');
        $.ajax({
          'url': form.attr('action'),
          'type': 'DELETE',
          'dataType': 'json',
          'data' : form.serializeObject(),
          'headers': {
            'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr('content')
          },
          'success'(resp){
            swal({
              title: 'Sucesso!',
              text: resp.message,
              icon: 'success',
              timer: 2000
            });

            $('input:checkbox:checked').each(function(){
              let id = $(this).val();

              $(`#result-${id}`).fadeOut(1500, function(){
                $(this).remove();
              });
            });
          },
          'error'(resp){
            swal({
              title: 'Oops!',
              text: resp.responseJSON.message,
              icon: 'error',
              timer: 2000
            });
          }
        });
      }
    })
    .catch(err => {});
  }
}

export default Confirm;
