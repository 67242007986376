class Modules {
  constructor() {
    $('.modules').on('show.bs.collapse', function (e) {
      $('.collapse').siblings().collapse('hide');

      // Disable menu
      $('.body').removeClass('-overlay');
      $('.js-toggle-menu').removeClass('-active');
      $('.menu').removeClass('-show');
    });

    window.addEventListener('click', function(e){
      let container = $('.modules.show');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.collapse('hide');
      }
    });
  }
}

export default Modules;
