import Common from './modules/common';
import Menu from './modules/menu';
import Modules from './modules/modules';
import Slide from './modules/slide';
import Gallery from './modules/gallery';
import Reorder from './modules/reorder';
import Upload from './modules/upload';
import Places from './modules/places';
import swal from '../../node_modules/sweetalert';

const availableModules = {
  Common,
  Menu,
  Modules,
  Slide,
  Gallery,
  Reorder,
  Upload,
  Places
};

window.modules = {};

$(() => {
  const htmlModules = $('[data-module]');

    // Loading htmlModules if they are in availableModules
  htmlModules.each((key, value) => {
    const mod = $(value).data('module');

    if (Object.prototype.hasOwnProperty.call(availableModules, mod)) {
      window.modules[mod] = new availableModules[mod]();
    } else {
      console.log(`The module "${mod}" does not exists.`);
    }
  });
});

$.fn.serializeObject = function()
{
  let o = {};
  let a = this.serializeArray();
  $.each(a, function() {
    if (o[this.name]) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    } else {
      o[this.name] = this.value || '';
    }
  });
  return o;
};

document.addEventListener('DOMContentLoaded', function(event) {
  console.log("%cMade in BRZ Digital! \ud83d\ude42", "font-size: 16px; color: #a7bf34");
});
