class Reorder {
  constructor() {
    let el = document.getElementById('reorder'),
        refer = el.dataset.endpoint;

    let sortable = new Sortable(el, {
      sort: true,
      dataIdAttr: 'data-id',
      onSort: function (e) {
        $.ajax({
          'url': refer,
          'type': 'POST',
          'dataType': 'json',
          'data': {
            list: sortable.toArray()
          },
          'headers': {
            'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr('content')
          },
          'success'(resp){
            swal({
              title: 'Sucesso!',
              text: resp.message,
              icon: 'success',
              timer: 2000
            });
          },
          'error'(resp){
            swal({
              title: 'Oops!',
              text: resp.responseJSON.message,
              icon: 'error',
              timer: 2000
            });
          }
        });
      }
    });
  }
}

export default Reorder;
