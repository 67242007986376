class Places {
  constructor() {
    const that = this;

    const placesConfig = [];
    let places = $('.js-cascading-places'),
        state = places.find('.state'),
        city = places.find('.city'),
        neighborhood = places.find('.neighborhood');

    if(state.length > 0) {
      let stateConfig = {
        selector: '.state',
        paramName: 'state_id',
        source: function(request, response) {
          $.getJSON(state.data('api'), request, function(data) {
            response($.map(data, function(item, index) {
              return {
                label: item,
                value: index,
                selected: index == state.data('value')
              };
            }));
          });
        }
      };

      placesConfig.push(stateConfig);
    }

    if(city.length > 0) {
      let cityConfig = {
        selector: '.city',
        paramName: 'city_id',
        requires: ['.state'],
        source: function(request, response) {
          $.getJSON(city.data('api'), request, function(data) {
            response($.map(data, function(item, index) {
              return {
                label: item,
                value: index,
                selected: index == city.data('value')
              };
            }));
          });
        }
      };

      placesConfig.push(cityConfig);
    }

    if(neighborhood.length > 0) {
      let neighborhoodConfig = {
        selector: '.neighborhood',
        requires: ['.state', '.city'],
        requireAll: true,
        source: function(request, response) {
          $.getJSON(neighborhood.data('api'), request, function(data) {
            response($.map(data, function(item, index) {
              return {
                label: item,
                value: index,
                selected: index == neighborhood.data('value')
              };
            }));
          });
        }
      };

      placesConfig.push(neighborhoodConfig);
    }

    $('.js-cascading-places').cascadingDropdown({
      selectBoxes: placesConfig
    });
  }
}

export default Places;
