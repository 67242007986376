Dropzone.autoDiscover = false;

class Upload {
  constructor() {
    let form = $('.js-dropzone');
    let settings = {
      legend: form.data('legend') || 'Solte os arquivos para enviar',
      accepted: form.data('accepted') || 'image/*'
    };

    form.dropzone({
      parallelUploads: 1,
      paramName: 'file',
      uploadMultiple: false,
      maxThumbnailFilesize: 100,
      maxFilesize: 100,
      dictDefaultMessage: settings.legend,
      acceptedFiles: settings.accepted,
      headers: {
        'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr('content')
      },
      init: function() {
        this.on('error', function(file, resp) {
          swal({
            title: 'Oops!',
            text: resp,
            icon: 'error',
            timer: 2000
          });
        });

        this.on('queuecomplete', function(file, resp) {
          swal({
            title: 'Sucesso!',
            text: 'Arquivos enviados com sucesso.',
            icon: 'success',
            timer: 2000
          });
        });
      }
    });
  }
}

export default Upload;
